<template>
    <div class="order-overview">
        <div v-if="order.item_description" class="description">{{ order.item_description }}</div>
        <div class="brand" :class="{ 'grid-span': !order.item_description && payment }">{{ order.brand_name }}</div>
        <Amount v-if="payment"
            :amount="payment.amount"
            :currency="payment.currency"
            :currencyDecimals="DECIMALS[payment.currency]"
            class="crypto-amount"
        />
        <FiatAmount
            :amount="order.fiat_price"
            :currency="order.fiat_currency_code"
        />
    </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Amount, FiatAmount } from '@nimiq/vue-components';
import { Order, Payment, Currency, LegacyCurrency } from '../lib/Types';

@Component({ components: { Amount, FiatAmount } })
export default class OrderOverview extends Vue {
    @Prop({ type: Object, required: true })
    public order!: Order;

    @Prop(Object)
    public payment?: Payment;

    private DECIMALS: Record<Currency | LegacyCurrency, number> = {
        [Currency.NIM]: 5,
        [Currency.BTC]: 8,
        [Currency.USDC]: 6,
        [LegacyCurrency.ETH]: 18,
    };
}
</script>

<style lang="scss" scoped>
    .order-overview {
        contain: layout paint style;
        display: grid;
        margin-top: -2.5rem;
        align-items: center;
        column-gap: 2.5rem;
        row-gap: 0.75rem;
    }

    .brand,
    .crypto-amount + .fiat-amount {
        font-size: 1.75rem;
        font-weight: 700;
        letter-spacing: .5px;
        opacity: .8;
    }

    .brand {
        font-size: 2.25rem;
        grid-row: 1;
        grid-column: 1;
        justify-self: start;

        /* Span two rows when crypto-amount is displayed, but not description */
        &.grid-span {
            grid-row-end: span 2;
        }
    }

    .description + .brand {
        font-size: 1.75rem;
    }

    .description,
    .crypto-amount {
        font-size: 2rem;
        font-weight: 500;
        opacity: .5;
        align-self: start;
    }

    .fiat-amount {
        grid-row: 1;
        grid-column: 2;
        justify-self: end;
    }

    /* Bigger digits when no crypto-amount is displayed */
    .brand + .fiat-amount {
        font-size: 5rem;
        font-weight: 600;
        opacity: .5;
    }

    /* Span two rows when description is displayed, but not crypto-amount */
    .description + .brand + .fiat-amount {
        grid-row-end: span 2;
    }

    .crypto-amount {
        grid-row: 2;
        grid-column: 2;
        justify-self: end;
    }

    @media (max-width: 475px), (max-height: 760px) {
        /* mobile font-size break point */
        .fiat-amount {
            font-size: 4.5rem;
        }
    }
</style>
