<template>
    <div class="success-status">
        <template v-if="order">
            <OrderOverview :order="order" :payment="payment" />
            <hr>
        </template>
        <div v-if="type === TYPES.BUYER" class="buyer-success">
            <p>{{ $t('This service was brought to you for free by Nimiq.') }}</p>
            <CtaButton @click.native="ctaUrl ? goToCustomCtaUrl() : visitNimiqCta()">
                <slot name="cta">{{ $t('Visit Nimiq.com') }}</slot>
            </CtaButton>
        </div>
        <div v-else-if="type === TYPES.SELLER" class="seller-success">
            <h2 class="nq-h2">{{ $t('Sign in for extra features.') }}</h2>
            <ul>
                <li>{{ $t('Dashboard with all past payments') }}</li>
                <li>{{ $t('Staff version for your employees') }}</li>
                <li>{{ $t('Pre-sets for quicker payments') }}</li>
                <li>{{ $t('100% for free')}}</li>
            </ul>
            <CtaButton @click.native="goToDashboardCta">{{ $t('Go to dashboard') }}</CtaButton>
        </div>
        <div v-if="type === TYPES.EMPLOYEE && explorerLink" class="employee-success">
            <p>{{ $t('Visit the blockchain explorer\nto trace your transaction.') }}</p>
            <CtaButton @click.native="goToExplorerLink">{{ $t('Visit now') }}</CtaButton>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Order, Payment } from '../lib/Types';
import OrderOverview from './OrderOverview.vue';
import CtaButton from './CtaButton.vue';

@Component({ components: {
    OrderOverview,
    CtaButton,
} })
class SuccessStatus extends Vue {
    @Prop({
        type: String as () => SuccessStatus.TYPES,
        default: () => SuccessStatus.TYPES.SELLER,
    })
    public type!: SuccessStatus.TYPES;

    @Prop(Object)
    public order?: Order;

    @Prop(String)
    public explorerLink?: string;

    @Prop(Object)
    public payment?: Payment;

    @Prop(String)
    public ctaUrl?: string;

    private readonly TYPES = SuccessStatus.TYPES;

    private mounted() {
        this.$emit(SuccessStatus.EVENTS.SHOW);
    }

    private beforeDestroy() {
        this.$emit(SuccessStatus.EVENTS.HIDE);
    }

    private visitNimiqCta() { // eslint-disable-line class-methods-use-this
        window.open('https://nimiq.com/', '_blank');
    }

    private goToDashboardCta() { // eslint-disable-line class-methods-use-this
        window.open(`${window.location.origin}/dashboard`, '_blank');
    }

    private goToCustomCtaUrl() {
        if (this.ctaUrl) window.location.assign(this.ctaUrl);
    }

    private goToExplorerLink() {
        // Open in new tab, to not lose current employee link URL
        if (this.explorerLink) window.open(this.explorerLink, '_blank');
    }
}

namespace SuccessStatus { // eslint-disable-line @typescript-eslint/no-namespace
    export enum TYPES {
        SELLER = 'seller',
        EMPLOYEE = 'employee',
        BUYER = 'buyer',
    }

    export enum EVENTS {
        SHOW = 'show-success-status',
        HIDE = 'hide-success-status',
    }
}

export default SuccessStatus;
</script>

<style lang="scss" scoped>
@import "../scss/mixins";

.success-status {
    contain: layout style; // no paint to not cutoff OrderOverview and hr that have a negative margin
}

hr {
    height: 2px;
    margin: 1.875rem -5rem 4.625rem;
    border: none;
    box-shadow: 0 1.5px 0 0 rgba(0, 0, 0, .1) inset;
}

.buyer-success {
    * { margin: 0 auto }

    p {
        font-size: 3rem;
        font-weight: 600;
        line-height: 150%;
        text-align: center;
        max-width: 320px;
        margin-top: 0;
        margin-bottom: 4rem;
    }
}

.seller-success {
    h2 {
        font-size: 3rem;
        margin-top: 0;
    }

    h2, ul {
        text-align: left;
    }

    ul {
        list-style: none;
        line-height: 150%;
        padding-left: 3rem;
        color: nimiq-blue(.7);
    }

    li:not(:first-child) {
        margin-top: 1.5rem;
    }

    ul li::before {
        content: "\2022";  /* \2022 is the CSS Code/unicode for a bullet */
        color: rgba(#21BCA5, .4);
        font-weight: bold;
        display: inline-block;
        width: 3rem;
        margin-left: -3.2rem;
    }

    button {
        margin: 0 auto;
        margin-top: 4rem;
    }
}

.employee-success {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 2rem;
    white-space: pre-line;

    p {
        margin: 0;
        opacity: 0.7;
    }
}

</style>
