<template>
    <div class="modal-mask" @click.self="close" :class="{ opened: isOpen }">
        <div class="nq-card modal">
            <CloseButton class="top-right" @click="close"/>
            <div class="modal-content markdown" v-html="CONTENT"></div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import { CloseButton } from '@nimiq/vue-components';
import Terms from '@/assets/TermsAndConditions.md';

@Component({ components: {
    CloseButton,
} })
export default class TermsModal extends Vue {
    private static readonly CONTENT: string = Terms;

    @Prop({
        type: String,
    })
    public htmlContent: string | undefined;

    private readonly CONTENT = TermsModal.CONTENT;

    private isOpen: boolean = false;

    public open() {
        this.isOpen = true;
    }

    private close() {
        this.isOpen = false;
    }
}
</script>

<style lang="scss" scoped>
    .modal-mask {
        contain: layout paint style;
    }

    .modal-mask,
    .modal {
        --transition-duration: 150ms;

        transition-duration: var(--transition-duration);
        transition-timing-function: var(--nimiq-ease);
    }

    .modal-mask {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;

        user-select: none;
        pointer-events: none;

        background-color: transparent;
        transition-property: background-color;

        &.opened {
            background-color: rgba(0, 0, 0, .4);
            pointer-events: all;
            overflow-y: auto;

            animation: fixscroll var(--transition-duration) 1;
            @keyframes fixscroll {
                0% { overflow: hidden }
                99% { overflow: hidden }
                100% { overflow-y: auto }
            }
        }
    }

    .modal {
        max-width: 102rem;
        padding: 1rem 4rem;
        transform: translateY(25rem);
        opacity: 0;
        transition-property: transform, opacity;
        margin-top: 15rem;

        .opened & {
            opacity: 1;
            transform: translateY(0);
        }
    }
</style>
