<template>
    <body>
        <div id="app" @focusin="fixIosInputFocus" @click="fixIosInputBlur"
            :class="{'landing': router.currentRoute.name === 'root'}"
        >
            <transition v-if="!showSuccessBackground" name="transition-fade">
                <div class="bg-gradient-center vue"></div>
            </transition>
            <transition name="transition-fade">
                <div v-if="showSuccessBackground" class="success-background"></div>
            </transition>
            <header>
                <a :href="logoUrl === '/img/logo.svg' ? '/' : 'javascript:history.back()'" class="logo-link">
                    <img :src="logoUrl" class="logo" :alt="$t('logo')" />
                    <span class="logo-name">CryptoPayment Link</span>
                </a>

                <div class="flex-grow"></div>

                <div class="menu">
                    <template v-if="brandName">
                        <span class="brand-name">{{ brandName }}</span>
                    </template>
                    <template v-else-if="auth.user === false">
                        <a class="nq-link" href="/dashboard">{{ $t('Login') }}</a>
                        <a class="nq-button-pill" href="/dashboard/login#signup">{{
                            $t('Get started')
                        }} <ArrowRightSmallIcon /></a>
                    </template>
                    <template v-else-if="auth.user">
                        <a class="nq-button-pill" href="/dashboard">
                            {{ $t('Go to Dashboard') }} <ArrowRightSmallIcon />
                        </a>
                    </template>

                    <LanguageSelector
                        v-if="$route.name !== 'root' && hideFooter"
                        v-model="language" :languages="SUPPORTED_LANGUAGES"
                    />
                </div>
            </header>

            <router-view :customization="customization"
                @openTerms="$refs.termsModal.open()"
                @hideAboutSection="hideAboutSection = true"
                @showAboutSection="hideAboutSection = false"
                @[SUCCESS_STATUS_EVENTS.SHOW]="showSuccessBackground = true"
                @[SUCCESS_STATUS_EVENTS.HIDE]="showSuccessBackground = false"
                @brandName="brandName = $event"
            />

            <footer v-if="$route.name === 'root' || !hideFooter">
                <LanguageSelector v-model="language" :languages="SUPPORTED_LANGUAGES"/>

                <div v-if="!hideFooter" class="flex-column">
                    <i18n path="Brought to you for free by {nimiq-logo}" tag="div" class="flex-row">
                        <template #nimiq-logo>
                            <a href="https://www.nimiq.com/cryptopaymentlink/" class="nq-link" target="_blank">
                                <img src="/img/nimiq_logo_white.svg" :alt="$t('Nimiq logo')" />
                            </a>
                        </template>
                    </i18n>
                    <small v-if="$route.name === 'pay'">
                        <a v-if="customization && customization.hide_about"
                            :href="isStaging() ? '/' : 'https://cplink.com'" target="_blank"
                            class="nq-link"
                        >{{ $t('Create your own link') }}</a>
                    </small>
                </div>

                <transition name="transition-fade">
                    <svg
                        v-if="!hideFooter
                            && $route.name === 'root'
                            && !hideAboutSection
                            && (!customization || !customization.hide_about)"
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="chevron-down"
                        @click="scrollToAbout"
                    >
                        <path d="m6 9 6 6 6-6"/>
                    </svg>
                </transition>
            </footer>

            <TermsModal ref="termsModal"/>
        </div>

        <About
            v-if="$route.name === 'root' && !hideAboutSection && !(customization && customization.hide_about)"
            ref="about"
        />
    </body>
</template>

<script lang="ts">
import '@nimiq/style/nimiq-style.min.css';
import '@nimiq/vue-components/dist/NimiqVueComponents.css';
import './scss/global.scss';

import { Component, Vue, Watch } from 'vue-property-decorator';
import { ArrowRightSmallIcon, LanguageSelector } from '@nimiq/vue-components';
import { BrowserDetection } from '@nimiq/utils';
import { Customization } from './lib/Types';
import { fetchCustomization } from './lib/Firestore';
import { BASE_API_URL, getUsername, isStaging } from './lib/Config';
import { loadCss } from './lib/Utils';
import { SUPPORTED_LANGUAGES, detectLanguage, loadLanguage } from './i18n/i18n-setup';
import TermsModal from './components/TermsModal.vue';
import SuccessStatus from './components/SuccessStatus.vue';
import auth, { userPromise } from './lib/Auth';
import router from './router';

// Lazy load About section which is below the fold.
const About = () => import('./components/About.vue');

@Component({ components: {
    TermsModal,
    ArrowRightSmallIcon,
    LanguageSelector,
    About,
} })
export default class App extends Vue {
    private static scrollTo(scrollContainer: Window | Element, top: number) {
        try {
            scrollContainer.scrollTo({
                top,
                behavior: 'smooth',
            });
        } catch (e) {
            scrollContainer.scrollTo(0, top);
        }
    }

    public readonly $refs!: {
        termsModal: TermsModal,
        about?: InstanceType<typeof import('./components/About.vue').default>,
    };

    public readonly $el!: HTMLElement;

    private readonly SUCCESS_STATUS_EVENTS = SuccessStatus.EVENTS;
    private readonly SUPPORTED_LANGUAGES = SUPPORTED_LANGUAGES;

    private isStaging = isStaging;

    private language = detectLanguage();
    private customization: Customization | null = null;
    private logoUrl = '/img/logo.svg';
    private hideAboutSection = false;
    private hideFooter = false;
    private showSuccessBackground = false;
    private iosVirtualKeyboardScrollPositions: number[] = [];
    private brandName = '';
    private backgroundCssUrl = '';
    private router = router;

    private auth = auth;

    private async created() {
        this.createManifest();

        this.onResize = this.onResize.bind(this);
        window.addEventListener('resize', this.onResize);
        this.onResize();

        const username = getUsername();

        if (username && username !== 'vendor') {
            const customization = await fetchCustomization(username);
            if (customization.exists) {
                this.customization = customization.data();

                if (this.customization.logo) {
                    this.logoUrl = `/api/logo?variant=pay${isStaging() ? `&user=${username}` : ''}`;
                }

                if (this.customization.title) {
                    document.title = `${this.customization.title} - ${document.title}`;
                }

                if (this.customization.background_position) {
                    this.$el.style.backgroundPosition = this.customization.background_position;
                }
            }
        }
    }

    @Watch('router.currentRoute')
    private async updateBackgroundCssUrl() { // eslint-disable-line class-methods-use-this
        const username = getUsername();

        let cssUrl = `${BASE_API_URL}/background`;

        if (router.currentRoute.name !== 'root') {
            if (username && username !== 'vendor') {
                cssUrl += `?user=${username}`;
            } else if (window.location.pathname.startsWith('/pay/')) {
                cssUrl += `?orderId=${window.location.pathname.split('/')[2]}`;
            } else if (window.location.pathname.startsWith('/employees/')) {
                cssUrl += `?staffId=${window.location.pathname.split('/')[2]}`;
            } else {
                const user = await userPromise;
                if (user) {
                    cssUrl += `?uid=${user.uid}`;
                }
            }
        }

        this.backgroundCssUrl = cssUrl;
    }

    @Watch('backgroundCssUrl')
    private loadBackgroundCss() {
        if (!this.backgroundCssUrl) return;
        loadCss(this.backgroundCssUrl).catch((error) => {
            // TODO: Handle CSS load error
            console.error(error); // eslint-disable-line no-console
        });
    }

    // eslint-disable-next-line class-methods-use-this
    private createManifest() {
        // Create pwa manifest dynamically.
        // Installed employee links should open as is, including the link id, and installations from all other routes
        // should start at the root page when opened. Specify absolute paths because relative paths would be tried to be
        // resolved relative to the manifest href which is a data url.
        const manifestContent = JSON.stringify({
            name: 'CryptoPayment Link',
            short_name: 'CPLink',
            icons: [{
                src: `${window.location.origin}/img/app-icons/android-icon-96x96.png`,
                sizes: '96x96',
                type: 'image/png',
            }, {
                src: `${window.location.origin}/img/app-icons/android-icon-144x144.png`,
                sizes: '144x144',
                type: 'image/png',
            }, {
                src: `${window.location.origin}/img/app-icons/android-icon-192x192.png`,
                sizes: '192x192',
                type: 'image/png',
            }, {
                src: `${window.location.origin}/img/app-icons/ms-icon-310x310.png`,
                sizes: '310x310',
                type: 'image/png',
            }],
            display: 'standalone',
            background_color: '#000000',
            scope: window.location.origin, // all paths on the domain should be in pwa scope
            // Where to start the pwa. This also uniquely identifies the pwa installation, such that multiple
            // installations for different staff links and root are possible at the same time,
            // see https://w3c.github.io/manifest/#id-member
            start_url: /^\/(?:app|staff|employees|e)\/.+/.test(window.location.pathname)
                ? `${window.location.origin}${window.location.pathname}` // employee link w/o potential query & fragment
                : window.location.origin, // start at root
        });
        const manifestElement = document.createElement('link');
        manifestElement.setAttribute('rel', 'manifest');
        manifestElement.setAttribute('href', `data:application/manifest+json,${encodeURIComponent(manifestContent)}`);
        document.head.appendChild(manifestElement);
    }

    private destroyed() {
        window.removeEventListener('resize', this.onResize);
    }

    @Watch('language') // eslint-disable-line class-methods-use-this
    private onLanguageChange(lang: string) {
        loadLanguage(lang);
    }

    private onResize() {
        this.hideFooter = window.innerWidth <= 750; // $tablet breakpoint
    }

    private async scrollToAbout() {
        if (this.hideAboutSection) {
            this.hideAboutSection = false;
            await Vue.nextTick(); // wait for Vue to apply the change
        }

        if (!this.$refs.about) return;
        App.scrollTo(document.body, document.body.scrollTop + this.$refs.about.$el.getBoundingClientRect().top);
    }

    private async fixIosInputFocus(event: FocusEvent) {
        // Fix Safari / Chrome iOS sometimes failing to scroll the focused input element into view when the virtual
        // keyboard opens.
        // Some background info: in contrast to Android browsers where the viewport and with it the html element shrinks
        // when the keyboard is opened, on iOS only the window resizes, but the size of its contents are unchanged and
        // only scrolled within the window, to scroll the focused input into view. Unfortunately, Apple's implementation
        // only really works flawlessly if the window is the regular scrolling element of the page content (and not e.g.
        // the body) and the content is actually long enough to be scrollable. In our case however, the <body> is the
        // scrolling element and the <html>'s and <body>'s size are fixed to 100% (the size of the visible area if the
        // keyboard is not open; regular window.innerHeight), i.e. the window itself if not regularly scrollable. We
        // fix the <html>'s and <body>'s size to achieve a background-attachment: fixed effect without actually using
        // background-attachment: fixed which is broken on iOS. Under these circumstances, the scrolling behaves as
        // follows:
        // Instead of fixing the window size to the available space above the virtual keyboard and just scrolling within
        // that area as one might expect, the window size is instead variable for whatever reason and depends on the
        // scroll position. The window scroll position + window innerHeight equate to the regular window innerHeight
        // when the the keyboard is not shown (100%). This means, when the window is at the top position (not scrolled),
        // the window still has the original height and the keyboard just covers part of it. When the window is scrolled
        // such that the bottom of the content aligns with the top of the keyboard, the window size matches the space
        // above the keyboard. Unfortunately, scrolling beyond that point is also possible, probably due to a buggy
        // implementation on Apple's side. When scrolling further, the window innerHeight shrinks beyond the available
        // space which leaves an empty space between the visible content and the keyboard.
        // As our inputs are positioned towards the end of the page on mobile, scrolling the window such that the page
        // bottom aligns with the top of the keyboard and the window size matches the available space above the keyboard
        // would be ideal. Unfortunately though, I couldn't find a way to know the size of the area above the keyboard,
        // to scroll the window accordingly. The best I could come up with via experimentation on an iPhone 13 Pro with
        // iOS 15.3 to calculate the size of the available space is the following, based on these measured values:
        // - screenSize: the entire screen's size (window.outerHeight)
        // - bottomOffset: size of the keyboard + iPhone's bottom bar (equates to maximum scrolled window.scrollY which
        //   can be obtained by window.scrollTo(0, some_high_number) and then reading window.scrollY)
        // - topOffset: size of the iPhone's top bar (can be roughly obtained by measuring an element with height 100vh
        //   and subtracting that from the screen's size. This leaves apparently the top and bottom bar. Dividing by 2
        //   gives then roughly the size of the top bar)
        // The available space above the keyboard is then screenSize - topOffset - bottomOffset
        // However, this calculation is complicated and fragile, as it might need to be adapted on other iPhone models
        // or iOS versions. For this reason, we're eventually using a different approach:
        // When Safari manages to scroll the page, which is most of the cases, we store that scroll position. While
        // picking the position that aligns the bottom of the page with the top of the keyboard would be ideal, all of
        // these scroll positions look decent as all our inputs have similar positions in the UI. However, some scroll
        // positions scroll less (if the input that was focused sits higher on the page) or more (basically on the first
        // page, where the about section is still visible). But as most of our inputs sit so low that the input can't be
        // centered without reaching the page bottom, Safari aligns to the page bottom for most inputs. By storing all
        // scroll positions and picking the median, we thus have a good chance of picking the ideal value for aligning
        // the page bottom with the top of the keyboard.

        if (!BrowserDetection.isIOS()) return;
        const input = event.target as HTMLInputElement | HTMLTextAreaElement;
        if (input.tagName !== 'INPUT' && input.tagName !== 'TEXTAREA') return;
        // Wait for Safari / Chrome iOS to focus the element and hopefully scroll.
        await new Promise<void>((resolve) => {
            let timeout = -1;
            const onResolve = () => {
                window.clearTimeout(timeout);
                window.removeEventListener('scroll', onResolve);
                resolve();
            };
            timeout = window.setTimeout(onResolve, 100);
            // Note: this fires only for Safari's window scrolling for the virtual keyboard, not for regular scrolling
            // on the page as that scrolls the body element.
            window.addEventListener('scroll', onResolve);
        });

        if (window.scrollY) {
            // Safari correctly scrolled the view, great. Store the scroll position.
            this.iosVirtualKeyboardScrollPositions.push(window.scrollY);
            return;
        }

        // Safari did not scroll
        if (!this.iosVirtualKeyboardScrollPositions.length) {
            // Failed even on first focus (which never happened while testing) or the iPhone / iPad is using an external
            // keyboard in which case the virtual keyboard does not show and the page doesn't need to be scrolled.
            return;
        }
        // Insertion via binary search would be nicer, but we're not expecting a lot of entries here and Safari failing
        // to scroll also only happens sometimes.
        this.iosVirtualKeyboardScrollPositions.sort();
        const medianIndex = Math.floor((this.iosVirtualKeyboardScrollPositions.length - 1) / 2);
        const scrollPosition = this.iosVirtualKeyboardScrollPositions[medianIndex];
        App.scrollTo(window, scrollPosition);
        console.log(`iOS scroll fix. Manually scrolling to ${scrollPosition}.`); // eslint-disable-line no-console
    }

    private fixIosInputBlur() { // eslint-disable-line class-methods-use-this
        // Fix Safari / Chrome iOS not blurring the currently focused input when clicking outside of it. Strangely,
        // making the "outside element" react to clicks by adding this no-op click listener, really already seems to be
        // enough to fix the issue.
    }
}
</script>

<style lang="scss" scoped>
    @import './scss/mixins';

    #app {
        --trTranslateX: 100px;

        contain: style; // no layout and paint because TermsModal should cover entire page
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        position: relative;
        isolation: isolate; // new stacking context to avoid success-background being placed behind app container
        z-index: 1; // make sure that TermsModal included in this stacking context is displayed above about section

        &.landing {
            max-height: 100%;
        }
    }

    .success-background {
        contain: size layout paint style;
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: var(--nimiq-green-bg);
        z-index: -1; // place behind other elements
    }

    header,
    footer {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
    }

    header {
        contain: layout style; // not paint because language selector overflows
        padding-left: 4rem;
        padding-right: 4rem;
        padding-top: 3rem;
        z-index: 1; // place language selector above router-view

        .logo-link {
            color: white;
            opacity: 0.8;
            text-decoration: none;
            display: flex;
            align-items: center;
            transition: opacity 200ms ease;

            min-width: 30rem;
            @media ($desktopM) {
                min-width: unset;
            }

            &:hover,
            &:focus {
                opacity: 1;
            }

            .logo {
                height: 3.5rem;
            }

            .logo-name {
                font-weight: bold;
                letter-spacing: 0.0625em;
                margin-left: 1.5rem;
                font-size: 2rem;

                @media ($tablet) {
                    display: inline;
                }

                @media ($mobileXL) {
                    display: none;
                }
            }
        }

        .menu {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            min-width: 30rem;
            @media ($desktopM) {
                min-width: unset;
            }
        }

        .nq-link {
            font-size: 2rem;
            font-weight: 500;
            color: white;
            cursor: pointer;
            white-space: nowrap;
        }

        .nq-button-pill {
            background: rgba(255, 255, 255, 0.8);
            color: inherit;
            height: 4.25rem;
            padding: 0 2.25rem;
            border-radius: 2.125rem;
            font-size: 2rem;
            font-weight: 600;
            display: flex;
            align-items: center;
            transition: background 200ms ease;
            white-space: nowrap;

            &:hover,
            &:focus {
                background: rgba(255, 255, 255, 1);

                .nq-icon {
                    transform: translateX(0.25rem);
                }
            }

            .nq-icon {
                font-size: 1.5rem;
                margin-left: 0.75rem;
                transition: transform 200ms ease;
            }
        }

        .nq-link + .nq-button-pill {
            margin-left: 3rem;
        }

        .brand-name {
            color: white;
            font-size: 1.75rem;
            font-weight: 600;
            border: 1px solid white;
            padding: 0.375rem 1rem;
            border-radius: 0.75rem;
            opacity: 0.8;
        }

        .language-selector {
            margin-left: 3rem;
            color: white;

            ::v-deep .list {
                top: -2rem;
                bottom: unset;
            }
        }
    }

    footer {
        contain: layout style; // not paint because language selector overflows
        justify-content: space-between;
        color: white;
        padding: 2.5rem 3rem;
        font-weight: 600;

        @media ($mobileL) {
            padding-top: 0;
        }

        .nq-link {
            color: white;
            cursor: pointer;

            &:hover {
                text-decoration: none;
            }
        }

        div.flex-column {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            text-shadow: 0 0 1rem #666;
            font-size: 2.25rem;
            line-height: 100%;

            small {
                margin-top: 1rem;
            }
        }

        div.flex-row {
            display: flex;
            align-items: center;

            .nq-link {
                height: 2.5rem;
                margin-left: 1rem;

                img {
                    height: 100%;
                }
            }
        }

        .chevron-down {
            --chevron-size: 6rem;
            position: absolute;
            width: var(--chevron-size);
            left: calc(50% - var(--chevron-size) / 2);
            fill: none;
            stroke: white;
            stroke-width: 2;
            stroke-linecap: round;
            stroke-linejoin: round;
            cursor: pointer;
            transition: transform .75s, opacity .5s var(--nimiq-ease); // opacity transition for transition-fade

            &:hover {
                --chevron-transition-extent: calc(var(--chevron-size) / 8);
                transform: translateY(var(--chevron-transition-extent)); // transition to start point of animation
                animation: chevron-bounce 1.25s .75s infinite alternate;

                @keyframes chevron-bounce {
                    from { transform: translateY(var(--chevron-transition-extent)); }
                    to { transform: translateY(calc(var(--chevron-transition-extent) * -1)); }
                }
            }
        }

        .language-selector ::v-deep .trigger::after {
            --arrow-color: white;
        }
    }
</style>
